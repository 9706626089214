import { SHA256 } from 'crypto-js';
import { Blockchain } from './blockchain';
import * as smartContractsRaw from '../smartContracts.json';

export class SmartContract {
  private blockchain: Blockchain;
  address: string;
  name: string;
  owner: string;
  chainId: number;
  balances: { [address: string]: number };
  stakes: any[]; // Tipe array untuk stakes
  tokenPairs: { [pair: string]: { rate: number, supply: number } };
  totalSupply: number;
  liquidityPools: {
    [pool: string]: {
      token1: { address: string, balance: number },
      token2: { address: string, balance: number },
      totalLiquidity: number
    }
  };

  constructor(name: string, owner: string, chainId: number, totalSupply: number, stakes: any[]) {
    this.name = name;
    this.owner = owner;
    this.chainId = chainId;
    this.address = this.generateContractAddress();
    this.balances = {};
    this.stakes = stakes || [
      {
        "stakeId": "stake123",
        "stakerAddress": "0xStakerAddress1",
        "amount": 100,
        "startDate": "2024-06-27",
        "endDate": "2024-07-27",
        "isActive": true,
        "reward": {
          "type": "fixed",
          "value": 10
        },
        "stakingPool": {
          "poolName": "pool1",
          "tokenAddress": "0xToken1Address",
          "totalStaked": 1000
        }
      },
      {
        "stakeId": "stake456",
        "stakerAddress": "0xStakerAddress2",
        "amount": 50,
        "startDate": "2024-06-28",
        "endDate": "2024-07-28",
        "isActive": true,
        "reward": {
          "type": "variable",
          "value": 5
        },
        "stakingPool": {
          "poolName": "pool2",
          "tokenAddress": "0xToken2Address",
          "totalStaked": 2000
        }
      }
    ];
    
    this.tokenPairs = {};
    this.totalSupply = totalSupply;
    this.liquidityPools = {
      "pool1": {
        "token1": {
          "address": "0xToken1Address",
          "balance": 1000
        },
        "token2": {
          "address": "0xToken2Address",
          "balance": 2000
        },
        "totalLiquidity": 3000
      },
      "pool2": {
        "token1": {
          "address": "0xToken3Address",
          "balance": 500
        },
        "token2": {
          "address": "0xToken4Address",
          "balance": 700
        },
        "totalLiquidity": 1200
      }
    };
  }

  public generateContractAddress(): string {
    const dataToHash = `${this.name}`;
    const hash = SHA256(dataToHash);
    const address = hash.toString().slice(0, 40);
    return `0x${address}`;
  }

  // Metode untuk menginisialisasi saldo pemilik smart contract
  private initializeOwnerBalance(): void {
    this.balances[this.owner] = this.totalSupply;
  }

  // Metode untuk mendapatkan saldo pemilik smart contract
  public async getOwnerBalance() {
    const data = smartContractsRaw;
    return data;
  }

  public async getLiquidity() {
    const data = smartContractsRaw;
    const liquidityPools = data.liquidityPools;
    const pool1 = liquidityPools.pool1;
    return pool1.totalLiquidity;
  }

  public execute(data: any): any {
    return data;
  }

  public becomeStaker(address: string, amount: number): boolean {
    // Ambil data dari local storage
    const smartContractsData = JSON.parse(localStorage.getItem('smartContracts'));

    // Loop melalui setiap kontrak pintar
    for (const contract of smartContractsData) {
      // Dapatkan saldo dari objek kontrak
      const balances = contract.balances;
      const stakers = contract.stakers;

      // Periksa apakah alamat yang diberikan memiliki saldo yang cukup
      if (balances[address] >= amount) {
        // Jika alamat telah menjadi staker sebelumnya, tambahkan jumlah baru ke staker yang ada
        if (contract.stakers[address]) {
          stakers[address] += amount;
        } else {
          // Jika alamat belum menjadi staker sebelumnya, buat entri baru
          stakers[address] = amount;
        }

        // Kurangi saldo dari alamat yang diberikan
        balances[address] -= amount;

        // Simpan perubahan ke local storage
        localStorage.setItem('smartContracts', JSON.stringify(smartContractsData));
        
        // Simpan ke blockchain
        this.saveBlockchain();

        console.log("smartContractsDataJSON", JSON.stringify(smartContractsData));
        return true;
      }
    }

    return false;
  }

  public getStakers(): string[] {
    return Object.keys(this.stakes); // Mengembalikan daftar alamat stakers sebagai array
  }

  // Method to check balance
  public getBalance(address: string): number {
    let balance = 0;

    // Ambil data dari local storage
    const smartContractsData = JSON.parse(localStorage.getItem('smartContracts'));

    // Loop melalui setiap kontrak pintar
    smartContractsData.forEach((contract: any) => {
      // Dapatkan alamat dan saldo dari objek kontrak
      const contractAddress = contract.address;
      const balances = contract.balances;

      // Cek apakah alamat yang dicari ada dalam objek balances kontrak saat ini
      const addressToCheck = address; // Ubah alamat yang ingin Anda periksa di sini
      if (addressToCheck in balances) {
        // Jika alamat ditemukan, simpan saldo dalam variabel balance
        balance = balances[addressToCheck];
        console.log(`Saldo di alamat ${addressToCheck} pada kontrak ${contractAddress}: ${balance}`);
      }
    });

    // Mengembalikan saldo dari alamat yang diberikan
    return balance;
  }

  // Method to check balance
  public getName(address: string): string {
    let name = '';

    // Ambil data dari local storage
    const smartContractsData = JSON.parse(localStorage.getItem('smartContracts'));

    // Loop melalui setiap kontrak pintar
    smartContractsData.forEach((contract: any) => {
      // Dapatkan alamat dan saldo dari objek kontrak
      const contractAddress = contract.address;
      const balances = contract.balances;
      const contractWallet = contract.contractWallet;
      const name = contract.name;
    });

    // Mengembalikan saldo dari alamat yang diberikan
    return name;
  }

  public setBalance(address: string, amount: number): void {
    this.balances[address] = amount;
  }

  // Method to send balance to minerRewardAddress
  public sendBalance(minerRewardAddress: string, amount: number): boolean {
    if (this.balances[this.owner] >= amount) {
      this.balances[this.owner] -= amount;
      this.balances[minerRewardAddress] = (this.balances[minerRewardAddress] || 0) + amount;
      return true;
    }
    return false;
  }

  public sendBalanceToAddress(walletAddress: string, amount: number): void {
    // Menambahkan saldo ke alamat dompet
    if (this.totalSupply >= amount) {
      this.totalSupply -= amount;
      this.balances[walletAddress] = (this.balances[walletAddress] || 0) + amount;
      this.saveBlockchain(); // Save the blockchain state after adding a transaction
    } else {
      console.log("Insufficient balance to send.");
    }
  }

  public sendTransferToAddress(sender: string, walletAddress: string, amount: number): void {
    // Ambil data dari local storage
    const smartContractsData = JSON.parse(localStorage.getItem('smartContracts'));

    // Loop melalui setiap kontrak pintar
    smartContractsData.forEach((contract: any) => {
      // Dapatkan saldo dari objek kontrak
      const balances = contract.balances;

      // Cek apakah alamat pengirim memiliki saldo yang cukup untuk mentransfer
      if (balances[sender] >= amount) {
        // Kurangi saldo pengirim
        balances[sender] -= amount;

        // Tambahkan saldo ke alamat dompet penerima
        balances[walletAddress] = (balances[walletAddress] || 0) + amount;

        // Simpan kembali data kontrak ke local storage setelah diperbarui
        localStorage.setItem('smartContracts', JSON.stringify(smartContractsData));

        console.log("Balance transfer successful.");
      } else {
        console.log("Insufficient balance to transfer.");
      }
    });
  }

  // Method to initialize balance (for demonstration purposes)
  public initializeBalance(address: string, amount: number): void {
    // Pastikan objek balances sudah ada
    if (!this.balances) {
      this.balances = {};
    }
    
    // Perbarui saldo untuk alamat dompet yang diberikan
    this.balances[address] = amount;
  }

  // Metode untuk mendapatkan status smart contract, termasuk balances
  public getStatus(): any {
    return {
      address: this.address,
      name: this.name,
      owner: this.owner,
      chainId: this.chainId,
      totalSupply: this.totalSupply,
      liquidityPools: this.liquidityPools,      
      balances: this.balances // Memasukkan balances ke dalam status
    };
  }

  // Method to get balance of all users from the smart contract
  public getAllBalances(): { [address: string]: number } {
    // Ambil data dari local storage
    const smartContractsData = JSON.parse(localStorage.getItem('smartContracts'));

    // Inisialisasi objek untuk menyimpan semua saldo
    let allBalances: { [address: string]: number } = {};

    // Loop melalui setiap kontrak pintar
    smartContractsData.forEach((contract: any) => {
      // Dapatkan saldo dari objek kontrak
      const balances = contract.balances;

      // Loop melalui setiap alamat dalam saldo
      for (const address in balances) {
        // Tambahkan atau perbarui saldo dalam objek allBalances
        if (balances.hasOwnProperty(address)) {
          allBalances[address] = (allBalances[address] || 0) + balances[address];
        }
      }
    });

    return allBalances;
  }

  // Method to stake tokens
  public stakeTokens(address: string, amount: number): boolean {
    const balance = this.getBalance(address);
    if (balance < amount) {
      console.log("Insufficient balance to stake.");
      return false;
    }

    // Deduct the staked amount from user's balance
    this.balances[address] -= amount;

    // Add the user to the stakers list if not already a staker
    if (!this.stakes[address]) {
      this.stakes[address] = amount;
    } else {
      this.stakes[address] += amount;
    }

    console.log(`${address} staked ${amount} tokens.`);
    this.saveBlockchain(); // Save the blockchain state after staking
    return true;
  }

  // Fungsi untuk menambahkan likuiditas ke pasangan token
  public addLiquidity(fromToken: string, toToken: string, rate: number, supply: number): void {
    // Ambil data dari local storage
    const smartContractsData = JSON.parse(localStorage.getItem('smartContracts'));

    // Loop melalui setiap kontrak pintar
    let fromContract;
    let toContract;

    for (const contract of smartContractsData) {
      if (contract.address === fromToken) {
        fromContract = contract;
      }
      if (contract.address === toToken) {
        toContract = contract;
      }
    }

    // Periksa apakah smart contract ditemukan
    if (!fromContract || !toContract) {
      console.error(`Smart contract for tokens not found`);
      return;
    }

    // Tambahkan atau perbarui likuiditas pada pasangan token
    fromContract.tokenPairs[toToken] = {
      rate: Number(rate),
      supply: Number(fromContract.tokenPairs[toToken]?.supply || 0) + Number(supply)
    };

    // Simpan perubahan ke local storage
    localStorage.setItem('smartContracts', JSON.stringify(smartContractsData));

    console.log(`Added liquidity: ${supply} ${fromToken} to ${toToken} with rate ${rate}`);
  }

  addTokenPair(pair: string, rate: number, supply: number): void {
    this.tokenPairs[pair] = { rate, supply };
  }

  // Fungsi untuk mendapatkan saldo wallet dari sebuah kontrak yang diberikan
  getBalanceContract(address: string, contract: any): number {
    return contract.balances[address] || 0;
  }

  swapTokens(fromAddress: string, fromToken: string, toToken: string, amount: number): boolean {
    // Ambil data dari local storage
    const smartContractsData = JSON.parse(localStorage.getItem('smartContracts'));

    let fromContract;
    let toContract;

    // Cari smart contract untuk fromToken dan toToken
    for (const contract of smartContractsData) {
      if (contract.address === fromToken) {
        fromContract = contract;
      }
      if (contract.address === toToken) {
        toContract = contract;
      }
    }

    // Periksa apakah smart contract ditemukan
    if (!fromContract || !toContract) {
      console.error(`Smart contract for tokens not found`);
      return false;
    }

    // Periksa pasangan token
    const tokenPair = fromContract.tokenPairs[toToken];
    if (!tokenPair) {
      console.error(`Token pair for ${fromToken} to ${toToken} not found`);
      return false;
    }

    // Dapatkan saldo dari alamat pengirim untuk token asal
    const fromBalance = this.getBalanceContract(fromAddress, fromContract);
    console.log(`Balance for ${fromToken} at ${fromAddress}:`, fromBalance);

    // Periksa apakah saldo cukup
    if (fromBalance < amount) {
      console.error(`Insufficient balance for address ${fromAddress}`);
      return false;
    }

    const { rate, supply } = tokenPair;
    const toAmount = amount * rate;

    // Periksa apakah supply token tujuan cukup
    if (toContract.totalSupply < toAmount) {
      console.error(`Insufficient supply for token ${toToken}`);
      return false;
    }

    // Kurangi saldo dari token asal di alamat pengirim
    fromContract.balances[fromAddress] -= amount;
    console.log(`New balance for ${fromToken} at ${fromAddress}:`, fromContract.balances[fromAddress]);

    // Kurangi supply dari token tujuan
    tokenPair.supply -= toAmount;
    toContract.totalSupply = tokenPair.supply;
    console.log(`New supply for ${toToken}:`, tokenPair.supply);

    // Tambahkan saldo token tujuan ke alamat pengirim
    toContract.balances[fromAddress] = (toContract.balances[fromAddress] || 0) + toAmount;
    console.log(`New balance for ${toToken} at ${fromAddress}:`, toContract.balances[fromAddress]);

    // Simpan perubahan ke local storage
    localStorage.setItem('smartContracts', JSON.stringify(smartContractsData));

    console.log(`Swapped ${amount} ${fromToken} for ${toAmount} ${toToken}`);
    return true;
  }

  private saveBlockchain(): void {
    localStorage.setItem('blockchain', JSON.stringify(this.blockchain));
  }

  private saveStaker(): void {
    // Simpan data staking ke localStorage
    localStorage.setItem('stakes', JSON.stringify(this.stakes));
  }

  toJSON(): any {
    return {
      // address: this.address,
      // name: this.stringToHex(this.name),
      // owner: this.stringToHex(this.owner),
      // chainId: '0x' + this.chainId.toString(16),
      // balances: this.balances,
      // stakes: this.stakes,
      // tokenPairs: this.tokenPairs,
      // totalSupply: '0x' + this.totalSupply.toString(16)
      address: this.address,
      name: this.name,
      owner: this.owner,
      chainId: this.chainId,
      balances: this.balances,
      liquidityPools: this.liquidityPools,
      stakes: this.stakes,
      tokenPairs: this.tokenPairs,
      totalSupply: this.totalSupply,
    };
  }

  stringToHex(str: string): string {
    let hex = '';
    for (let i = 0; i < str.length; i++) {
      hex += '' + str.charCodeAt(i).toString(16);
    }
    return '0x' + hex;
  }

  static fromJSON(data: any): SmartContract {
    const contract = new SmartContract(data.name, data.owner, data.chainId, data.totalSupply, data.stakes);
    contract.balances = data.balances;
    contract.stakes = data.stakes;
    contract.tokenPairs = data.tokenPairs;
    contract.liquidityPools = data.liquidityPools;
    return contract;
  }
}
